<template>
  <v-card
  class="OnHover pa-3"
  >
    <v-toolbar class="noshadow equ-toolbar">
      <v-btn-toggle
        v-model="selectedOption"
        color="deep-purple accent-3"
      >
        <v-btn
          value="deviceType"
          text
        >
          Device Type
        </v-btn>
        <v-btn
          value="make"
          text
        >
          Device Make
        </v-btn>
        <v-btn
          value="deviceName"
          text
        >
          Device Name
        </v-btn>
      </v-btn-toggle>
      <v-spacer />
      <v-chip
        v-if="showFilterText"
        class="ma-2"
        close
        color="teal"
        text-color="white"
        @click="removeFilter"
      >
        Filtering by {{ filterText }}
      </v-chip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="50"
      :single-select="singleSelect"
      @click:row="handleRowClick"
      dense
    >
      <template v-slot:item.deviceType="{ item }">
        <div>
          <v-icon v-if="item.rowIndex == selectedRow">
            mdi-forward
          </v-icon> {{ item.deviceType }}
        </div>
      </template>
    <!--<template v-slot:item.qty="{ item }">
      <v-chip :color="getColor(item)" dark>{{ item.qty }}</v-chip>
    </template>-->
    </v-data-table>
    <v-btn 
      color="primary"
      dark
      class="mb-2 pa-0"
      :before-generate="beforeGenerateExcel"
      :before-finish="beforeFinishExcel"
      @click="handleDownload"
    >
      Download <v-icon
        small
        class="mr-1"
        right
      >
        mdi-download
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
  import store from '@/store'
  import Utils from '@/api/util'
  import moment from 'moment'
  import { isEmpty } from 'lodash'
  import { findEquipmentSummary } from '@/api/PouchDaoReport'
  import { mapGetters } from 'vuex'

  export default {
    name: 'EquipmentDeviceSummaryTable',
    data () {
      return {
        excelMeta: [
          [
            {
              key: 'charset',
              value: 'utf-8',
            },
          ],
        ],
        excelTitle: 'Equipment Data',
        excelFileName: 'Equipment Data Export',
        excelFileType: 'xlsx',
        chipSelection: 0,
        singleSelect: true,
        selectedRow: -1,
        filterText: '',
        showFilterText: false,
        selectedOption: 'deviceType', // deviceType, make, deviceName
        baseHeaders: [
          {
            text: 'Device Type',
            align: 'left',
            value: 'deviceType',
          },
          { text: 'Device Make', value: 'make' },
          { text: 'Device Name', value: 'deviceName' },
        ],
        tableData: [],
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('equipment', {
        refreshRequired: 'refreshRequired',
        equipmentTableFilter: 'equipmentTableFilter',
        equipmentChartFilter: 'equipmentChartFilter',
      }),
      headers: function () {
        const latestMonth = moment(this.customerInfo.latest + '01', 'YYYYMMDD').format('MMM YY')
        return this.baseHeaders.concat([
          { text: 'Devices', value: 'qty' },
          { text: latestMonth + ' Total', value: 'billTotal' },
          { text: '12 Mth Avg', value: 'averageBillTotal' },
          { text: latestMonth + ' Data', value: 'domesticDataUsage' },
          { text: '12 Mth Avg Data', value: 'averageUsageTotal' },
          { text: latestMonth + ' Call Count', value: 'domesticDataCall' },
          { text: '12 Mth Avg Call Count', value: 'averageCallTotal' },
        ])
      },
      excelHeaderRow: function () {
        const excelHeader = {}
        this.headers.forEach(header => {
          excelHeader[header.text] = header.value
        })
        return excelHeader
      },
    },
    watch: {
      equipmentTableFilter: { // this is set from EquipmentTopTenPieChart.vue which dispatch the value to vuex
        handler () {
          console.log('refreshRequired equipmentTableFilter changed to category ' + this.equipmentTableFilter.category, this.equipmentTableFilter)
          this.filterDataRaw(this.equipmentTableFilter.category, this.equipmentTableFilter)
          this.updateFilterText(this.equipmentTableFilter)
        },
      },
      refreshRequired: {
        handler () {
          if (this.refreshRequired) {
            this.filterData(this.selectedOption)
          }
        },
      },
      selectedOption: {
        handler () {
          console.log('refreshRequired changed toggleDeviceType to ' + this.selectedOption)
          this.filterDataFromTab(this.selectedOption)
        },
      },
    },
    mounted () {
      // this.updateEquipmentSummary()
      this.filterData(this.selectedOption)
    },
    methods: {
      getSummaryQtyColor (item) {
        if (item.qty > 400) return 'red'
        else if (item.qty > 200) return 'orange'
        else return 'green'
      },
      updateFilterText (equipmentTableFilter) {
        console.log('updateFilterText', equipmentTableFilter)
        if (isEmpty(equipmentTableFilter)) {
          this.filterText = ''
          this.showFilterText = false
          console.log('setting filterText to empty space')
        } else {
          this.filterText = equipmentTableFilter.label
          this.showFilterText = true
          this.selectedRow = -1
          console.log('setting filterText to ' + this.filterText)
        }
      },
      removeFilter () {
        console.log('remove filter called', this.filterText)
        this.updateFilterText(null)
        this.filterData(this.selectedOption)
        // const filterEvent = { category: this.selectedOption, label: null }
        store.dispatch('equipment/updateEquipmentTableFilter', {})
        store.dispatch('equipment/clearEquipmentChartFilter')
        // store.dispatch('equipment/updateSnTableFilter', {})
      },
      handleRowClick (value) {
        this.selectedRow = value.rowIndex
        if (this.selectedOption === 'deviceType') {
          store.dispatch('equipment/updateSnTableFilter', { category: 'deviceType', deviceType: value.deviceType })
        }
        if (this.selectedOption === 'make') {
          store.dispatch('equipment/updateSnTableFilter', {
            category: 'make',
            deviceType: value.deviceType,
            make: value.make,
          })
        }
        if (this.selectedOption === 'deviceName') {
          store.dispatch('equipment/updateSnTableFilter', {
            category: 'deviceName',
            deviceType: value.deviceType,
            make: value.make,
            deviceName: value.deviceName,
          })
        }
      },
      updateEquipmentSummary () {
        console.log('updateEquipmentSummary started')
        // try to update the headers...
        findEquipmentSummary({}).then(result => {
          this.resetData(result.equipmentSummaryList)
          // store.dispatch('app/setProgress', false)
        })
      },
      updateChipSelection (selectedOption) {
        if (this.selectedOption === 'deviceType') {
          this.chipSelection = 0
        }
        if (selectedOption === 'make') {
          this.chipSelection = 1
        }
        if (selectedOption === 'deviceName') {
          this.chipSelection = 2
        }
      },
      // this is called from the tab headers as well hence single argument
      filterDataFromTab (selectOption) { // also reset filter to null is showing all records...
        this.selectedRow = -1
        console.log('filterDataFromTab with selectOption ' + selectOption + ' with equipment table filter ', this.equipmentTableFilter)
        if (isEmpty(this.equipmentTableFilter)) {
          this.filterDataRaw(selectOption, null)
        } else {
          this.filterDataRaw(selectOption, this.equipmentTableFilter)
        }
        // this.updateFilterText(null)
      },
      filterData (selectOption) { // also reset filter to null is showing all records...
        console.log('filterData with selectOption ' + selectOption + ' with equipment table filter ', this.equipmentTableFilter)
        this.filterDataRaw(selectOption, null)
        // this.updateFilterText(null)
      },
      filterDataRaw (selectOption, filterOption) { // selectOption 'deviceName' filterOption {category: 'make', label: 'APPLE'}
        console.log('filterDataRaw selectOption ' + selectOption, filterOption)
        this.selectedOption = selectOption
        this.updateChipSelection(selectOption)
        // try to reduce the data by type
        findEquipmentSummary(filterOption, this.equipmentTableFilter).then(result => {
          const reducedResultMap = result.equipmentSummaryList.reduce(
            (map, item) => {
              // console.log('filterData reduce item is ' + item.deviceType)
              let key = item.deviceType
              if (this.selectedOption === 'make') {
                key += '-' + item.make
              }
              if (this.selectedOption === 'deviceName') {
                key += '-' + item.make + '-' + item.deviceName
              }
              const {
                billTotal,
                qty,
                domesticDataUsage,
                domesticDataCall,
                bill12MonthsTotal,
                bill12MonthsCount,
                domesticDataUsage12MonthsTotal,
                domesticDataUsage12MonthsCount,
                domesticDataCall12MonthsTotal,
                domesticDataCall12MonthsCount,
              } = item
              // console.log('filterData reduce item is ' + key + ' billTotal ' + billTotal)
              const prev = map.get(key)
              if (prev) {
                prev.qty += qty
                prev.billTotal += billTotal
                prev.domesticDataUsage += domesticDataUsage
                prev.domesticDataCall += domesticDataCall
                prev.bill12MonthsTotal += bill12MonthsTotal
                prev.bill12MonthsCount += bill12MonthsCount
                prev.domesticDataUsage12MonthsTotal += domesticDataUsage12MonthsTotal
                prev.domesticDataUsage12MonthsCount += domesticDataUsage12MonthsCount
                prev.domesticDataCall12MonthsTotal += domesticDataCall12MonthsTotal
                prev.domesticDataCall12MonthsCount += domesticDataCall12MonthsCount
              } else {
                map.set(key, Object.assign({}, item))
              }
              return map // need to return here so it can be accumulated
            },
            new Map(),
          )
          const monthTotalList = [...reducedResultMap.values()]
          // console.log(monthTotalList)
          this.resetData(monthTotalList)
        })
      },
      resetData (result) {
        // TODO calculate average here and format data
        console.log('resetData called for ' + this.selectedOption)
        let rowIndex = 0
        result.forEach(equipmentSummary => {
          if (equipmentSummary.bill12MonthsCount > 0) {
            equipmentSummary.averageBillTotal = Utils.formatCurrency(equipmentSummary.bill12MonthsTotal / equipmentSummary.bill12MonthsCount)
          } else {
            equipmentSummary.averageBillTotal = Utils.formatCurrency(0.00)
          }
          equipmentSummary.billTotal = Utils.formatCurrency(equipmentSummary.billTotal)

          if (equipmentSummary.domesticDataUsage12MonthsCount > 0) {
            equipmentSummary.averageUsageTotal =
              Utils.readableBytes(equipmentSummary.domesticDataUsage12MonthsTotal / equipmentSummary.domesticDataUsage12MonthsCount)
          } else {
            equipmentSummary.averageUsageTotal = Utils.readableBytes(0)
          }
          if (equipmentSummary.domesticDataCall12MonthsCount > 0) {
            equipmentSummary.averageCallTotal = Math.round(equipmentSummary.domesticDataCall12MonthsTotal / equipmentSummary.domesticDataCall12MonthsCount)
          } else {
            equipmentSummary.averageCallTotal = 0
          }
          equipmentSummary.domesticDataUsage = Utils.readableBytes(equipmentSummary.domesticDataUsage)
          equipmentSummary.rowIndex = rowIndex++
        })
        if (this.selectedOption === 'deviceType') {
          this.baseHeaders = [{
            text: 'Device Type',
            align: 'left',
            value: 'deviceType',
          },
          ]
        }
        if (this.selectedOption === 'make') {
          this.baseHeaders = [{
                                text: 'Device Type',
                                align: 'left',
                                value: 'deviceType',
                              },
                              { text: 'Device Make', value: 'make' },
          ]
        }
        if (this.selectedOption === 'deviceName') {
          this.baseHeaders = [
            {
              text: 'Device Type',
              align: 'left',
              value: 'deviceType',
            },
            { text: 'Device Make', value: 'make' },
            { text: 'Device Name', value: 'deviceName' },

          ]
        }
        this.tableData = result
        store.dispatch('equipment/refreshRequired', false)
      },
      exportToExcel () {
        return this.tableData
      },
      beforeGenerateExcel () {
        store.dispatch('app/setProgress', true)
      },
      beforeFinishExcel () {
        store.dispatch('app/setProgress', false)
      },
      getColor (sn) {
        return 'green'
      },
      handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = []
          const filterVal = []

          this.headers.forEach((item, index) => {
            tHeader.push(item.text)
            filterVal.push(item.value)
          });
                    
          const list = this.tableData
          const data = list.map( v => filterVal.map(j => v[j] ))  
          excel.export_json_to_excel({
            header: tHeader,
            sheetTitle: 'Equipment',
            data,
            filename: this.excelFileName,
            autoWidth: this.autoWidth,
            bookType: this.excelFileType
          })
        })
      }
    },
  }
</script>

<style scoped>
.v-application .deep-purple--text.text--accent-3 {
  color: #ea74cc !important;
  caret-color: #893273 !important;
}
</style>
