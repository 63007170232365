<template>
    <v-card
    class="OnHover pa-3"
    >
       <v-simple-table width="50%">
        <tr>
          <td>
            <span
              class="text--header--breadcrumb pl-3"
            >
            {{ filterText }}
          </span>
          </td>
          <td>
            <v-spacer />
            <v-text-field
              v-model="searchEquipment"
              append-icon="mdi-magnify"
              class="ml-auto mb-2"
              label="Search"
              hide-details
              single-line
              style="max-width: 250px;"
            />
          </td>
        </tr>
      </v-simple-table>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="30"
        :search="searchEquipment"
        @click:row="handleRowClick"
        dense
      >
        <template v-slot:item.sn="{ item }">
          <v-icon v-if="item.rowIndex == selectedRow">
            mdi-forward
          </v-icon> {{ item.sn }}
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
  import Utils from '@/api/util'
  import store from '@/store'
  import moment from 'moment'
  import { createNamespacedHelpers } from 'vuex'
  import { findEquipmentDataTable } from '@/api/PouchDaoReport'
  const { mapGetters } = createNamespacedHelpers('equipment')
  export default {
    name: 'EquipmentDataTable',
    // props: {
    //   category: {
    //     type: String,
    //     default: 'DOMESTIC DATA',
    //   },
    // },
    computed: {
      ...mapGetters([
        'refreshRequired',
        'snTableFilter',
      ]),
      filterText () {
        if (this.snTableFilter.category === 'deviceType') {
          return this.snTableFilter.deviceType
        }
        if (this.snTableFilter.category === 'make') {
          return this.snTableFilter.deviceType + '  >  ' + this.snTableFilter.make
        }
        if (this.snTableFilter.category === 'deviceName') {
          return this.snTableFilter.deviceType + '  >  ' + this.snTableFilter.make + '  >  ' + this.snTableFilter.deviceName
        }
        return null
      },
    },
    watch: {
      snTableFilter: {
        handler () {
          // deviceType
          // make
          // deviceName
          console.log('snTableFilter changed to deviceType ' + this.snTableFilter.deviceType + ' make ' + this.snTableFilter.make + ' deviceName ' + this.snTableFilter.deviceName)
          this.updateEquipmentDetailTable(this.snTableFilter)
        },
      },
      refreshRequired: {
        handler () {
          if (this.refreshRequired) {
            this.updateHeaders()
          }
        },
      },
    },
    data () {
      return {
        selectedRow: -1,
        searchEquipment: '',
        headers: [
          {
            text: 'Mobile Numbers',
            align: 'left',
            sortable: false,
            value: 'sn',
          },
          { text: 'Jan', value: '0' },
          { text: 'Feb', value: '0' },
          { text: 'Mar', value: '0' },
          { text: 'Apr', value: '0' },
          { text: 'May', value: '0' },
        ],
        tableData: [],
      }
    },
    mounted () {
      this.updateHeaders()
    },
    methods: {
      handleRowClick (value) {
        console.log('handleRowClick rowindex is ' + value.rowIndex)
        this.selectedRow = value.rowIndex
        store.dispatch('equipment/updateSnFilter', { sn: value.sn })
      },
      updateEquipmentDetailTable (snTableFilter) {
        // if (snTableFilter.deviceName) {
        //   console.log('deviceName is ' + this.snTableFilter.deviceName)
        // }
        findEquipmentDataTable(snTableFilter).then(result => {
          this.selectedRow = -1
          this.resetData(result)
        })
      },
      updateHeaders () {
        console.log('updateHeaders')
        // try to update the headers...
        let dataHeaders = []
        findEquipmentDataTable({ category: 'ALL' }).then(result => {
          dataHeaders.push({ text: 'Mobile No.', value: 'sn', align: 'left' })
          dataHeaders.push({ text: 'Name', value: 'userName' })
          dataHeaders.push({ text: 'Device Make', value: 'make' })
          dataHeaders.push({ text: 'Device Name', value: 'deviceName' })
          dataHeaders.push({ text: 'Device Model', value: 'model' })
          dataHeaders.push({ text: 'Operating System', value: 'os' })
          dataHeaders.push({ text: 'IMEI', value: 'imei' })
          dataHeaders.push({ text: 'SIM Number', value: 'sim' })
          this.headers = dataHeaders
          this.resetData(result)
        })
      },
      resetData (result) {
        let dataRows = []
        let rowIndex = 0
        result.forEach(row => {
          const data = row.doc
          data.rowIndex = rowIndex++
          dataRows.push(data)
        })
        this.tableData = dataRows
        store.dispatch('equipment/refreshRequired', false)
      },
      getColor (item) {
        // console.log('sn is ' + item.sn)
        return 'green'
      },

    },
  }
</script>

<style scoped>
</style>
